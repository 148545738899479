<template>
	<div class="contact-form" :class="{ invert: invert }">
		<form @submit.prevent="submit">
			<div class="grid-form mb-4">
				<div class="input">
					<input type="text" :placeholder="$t('contact.name')" />
				</div>
				<div class="input">
					<input type="text" :placeholder="$t('contact.phone')" />
				</div>
				<div class="input grid-full">
					<input type="email" :placeholder="$t('contact.email')" />
				</div>
				<div class="input input--textarea grid-full h-auto">
					<textarea
						name=""
						id=""
						cols="30"
						rows="3"
						:placeholder="$t('contact.message')"
					></textarea>
				</div>
			</div>
			<button
				role="submit"
				class="button button--main text-uppercase"
				:disabled="loading"
			>
				<span v-if="!loading">
					{{ $t("buttons.send") }}
				</span>
				<span v-else class="button__loader" style="--color: white"></span>
			</button>
		</form>
	</div>
</template>

<script>
export default {
	props: ["invert"],

	data() {
		return {
			name: null,
			phone: null,
			email: null,
			message: null,

			loading: false,
		};
	},
	methods: {
		submit() {
			this.loading = true;
			this.$store
				.dispatch("feedback/feedback", {
					name: this.name,
					email: this.email,
					phone: this.phone,
					body: this.message,
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss">
.contact-form {
	.grid-form {
		display: grid;
		gap: 1rem;
		grid-template-columns: 1fr 1fr;
		@media (max-width: 576px) {
			grid-template-columns: 1fr;
		}
		@media (min-width: 576px) {
			.grid-full {
				grid-column-start: 1;
				grid-column-end: 3;
			}
		}
		.input {
			background: var(--white-color);
			color: #444;
			border-radius: 2rem;
			height: 3rem;
			padding: 0.4rem 1.5rem;
			&--textarea {
				padding: 1.5rem;
			}
			textarea,
			input {
				height: 100%;
				width: 100%;
				font-size: 0.8rem;
				resize: none;
			}
		}
	}
	.button {
		font-size: 0.9rem;
	}
}
.invert {
	.input {
		background: #eee !important;
	}
	@media (max-width: 992px) {
		.input {
			grid-column-start: 1;
			grid-column-end: 3;
		}
	}
}
</style>