<template>
	<div
		class="container"
		data-aos="fade"
		data-aos-anchor-placement="top-buttom"
		data-aos-duration="500"
	>
		<loader v-if="loading"></loader>
		<div class="main py-5" v-else>
			<h2 class="text-capitalize fw-bold">
				{{ $t("search-for") }} {{ $route.query.q }}
			</h2>
			<div class="row mt-5">
				<product-card
					class="col-lg-3 col-md-4 col-sm-6 col-12 px-3 mb-4"
					data-aos="fade"
					data-aos-anchor-placement="top-center"
					v-for="product in products"
					:key="product.id"
					:product="product"
				></product-card>
				<div
					class="col-12 fs-4 text-muted text-uppercase"
					v-if="!products.length"
				>
					{{ $t("no-products") }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import ProductCard from "@/components/ProductCard.vue";
export default {
	components: { Loader, ProductCard },
	data() {
		return {
			loading: true,
			products: [],
		};
	},
	mounted() {
		this.fetch();
	},
	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch("products/fetchBySearch", { search: this.$route.query.q })
				.then((res) => {
					this.products = res.Products;
				})
				.then(() => {
					this.loading = false;
				});
		},
	},
	watch: {
		"$route.query"() {
			this.fetch();
		},
	},
};
</script>

<style scoped>
.main {
	min-height: 50vh;
}
</style>