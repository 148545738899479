<template>
	<div class="">
		<div class="container">
			<breadcrumb></breadcrumb>
			<div class="main row">
				<div class="product-images col-md-7 col-12 d-flex flex-row-reverse">
					<div class="main-image">
						<div class="image">
							<img src="@/assets/Products/cardamom seeds.png" alt="" />
						</div>
					</div>
					<div class="other-image d-flex flex-column">
						<div class="other-image__item">
							<div class="image">
								<img src="@/assets/Products/Basmati goldarn rice.png" alt="" />
							</div>
						</div>
						<div class="other-image__item">
							<div class="image">
								<img src="@/assets/Products/Basmati goldarn rice.png" alt="" />
							</div>
						</div>
						<div class="other-image__item">
							<div class="image">
								<img src="@/assets/Products/Basmati goldarn rice.png" alt="" />
							</div>
						</div>
						<div class="other-image__item">
							<div class="image">
								<img src="@/assets/Products/Basmati goldarn rice.png" alt="" />
							</div>
						</div>
					</div>
				</div>
				<div class="product-text col-md-5 col-12">
					<h1 class="product-title">Basmati goldarn rice</h1>
					<p>properties</p>
					<ul>
						<li>product of india</li>
						<li>20 Kg weight</li>
					</ul>
					<div class="product-text-line"></div>
					<div class="product-description">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum,
						assumenda quo. Eos animi, odit architecto incidunt doloribus
						doloremque vero corporis voluptate ipsa temporibus aspernatur
						assumenda consequuntur quod laudantium libero nihil.
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
	components: { Breadcrumb },
	comments: {
		Breadcrumb,
	},
};
</script>

<style>
</style>