import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./lang/index"
import axios from 'axios'
import URL from "./constants"
import 'aos/dist/aos.css'

Vue.config.productionTip = false

window.axios = axios.create({
  baseURL: URL
})
window.axios.defaults.headers.common['accept-language'] = i18n.locale;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
