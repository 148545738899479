<template>
	<div>
		<loader v-if="loading"></loader>
		<div
			class="main"
			v-else
			data-aos="fade"
			data-aos-anchor-placement="top-buttom"
			data-aos-duration="500"
		>
			<div class="container">
				<breadcrumb
					class="mt-4"
					:links="[
						{ name: $t('breadcrumbs.home') },
						{ name: $t('breadcrumbs.about') },
					]"
				></breadcrumb>
				<div class="row mt-4">
					<div class="col-md-6 col-12">
						<h2
							class="about-title fw-bold t-c text-uppercase"
							v-html="$t('about-us.who-we-are-title')"
						></h2>
						<div class="" v-html="about"></div>
					</div>
					<div class="col-md-6 col-12 we-are-image mt-md-0 mt-4">
						<div class="image">
							<img src="@/assets/Images/Header_background.jpg" alt="" />
						</div>
					</div>
				</div>
				<div class="quality my-5">
					<h2
						class="w-100 about-title fw-bold t-c text-uppercase mb-4"
						v-html="$t('about-us.quality-title')"
					></h2>
					<div
						class="quality__item d-flex flex-md-nowrap flex-wrap mb-5"
						v-for="(oneQuality, i) in qualityArr"
						:key="oneQuality.id"
						:class="{ 'flex-row-reverse': !(i % 2) }"
					>
						<div class="quality-text">
							<p v-html="oneQuality.Content"></p>
						</div>
						<div class="quality-images d-flex justify-content-evenly">
							<div class="quality-images__item" v-if="oneQuality.images[0]">
								<div class="image">
									<img :src="oneQuality.images[0]" alt="" />
								</div>
							</div>
							<div class="quality-images__item" v-if="oneQuality.images[1]">
								<div class="image">
									<img :src="oneQuality.images[1]" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="brands">
				<div
					class="
						brands-list
						d-flex
						justify-content-sm-evenly justify-content-around
					"
				>
					<div class="brands-list__item">
						<div class="image">
							<img src="@/assets/Images/Logo.png" alt="" />
						</div>
					</div>
					<div class="brands-list__item">
						<div class="image">
							<img src="@/assets/Images/Olabi.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Loader from "@/components/Loader.vue";
import { mapGetters, mapState } from "vuex";

export default {
	components: {
		Breadcrumb,
		Loader,
	},

	data() {
		return {
			loading: false,
		};
	},

	computed: {
		qualityArr() {
			return this.quality.sort((a, b) => a.id - b.id);
		},
		...mapGetters({
			about: "aboutUs/getAbout",
		}),
		...mapState({
			quality: (state) => state.QM.qm,
		}),
	},

	methods: {
		// async fetch() {
		// 	this.loading = true;
		// 	await ;
		// 	await this.$store.dispatch("QM/fetchAll");
		// 	this.loading = false;
		// },
	},

	mounted() {
		// this.fetch();
	},
};
</script>

<style scoped lang="scss">
@import "../../mixins.scss";
.about-title {
	line-height: 1.25em;
	margin-bottom: 0.4em;
}
.we-are-image {
	height: 450px;
	@media (max-width: 768px) {
		height: 300px;
	}
	.image {
		overflow: hidden;

		border-radius: 20px;
		img {
			object-fit: cover;
		}
	}
}
.quality-text {
	@include paddingEnd(1rem);
}
.quality-images {
	@media (max-width: 768px) {
		width: 100%;
	}
	&__item {
		width: 280px;
		height: 380px;
		@media (max-width: 1400px) {
			width: 180px;
			height: 250px;
		}
		@media (max-width: 768px) {
			width: 50%;
			height: 40vw;
		}
		border-radius: 15px;
		overflow: hidden;

		// &:first-child {
		// }
		&:nth-child(2) {
			@media (min-width: 768px) {
				margin-top: 5rem;
			}
		}
		img {
			object-fit: cover;
		}
	}
}
.quality__item:nth-child(even) {
	.quality-images__item {
		@include marginEnd(2.5rem);
		@media (max-width: 992px) {
			@include marginEnd(1rem);
		}
		@media (max-width: 768px) {
			&:last-child {
				@include marginEnd(0);
			}
		}
	}
}
.quality__item:nth-child(odd) {
	.quality-images__item {
		@include marginStart(2.5rem);
		@media (max-width: 992px) {
			@include marginStart(1rem);
		}
		@media (max-width: 768px) {
			&:first-child {
				@include marginStart(0);
			}
		}
	}
}
.brands {
	padding: 3rem 10%;
	background: var(--third-color);
	&-list {
		margin: auto;
		&__item {
			height: 80px;
			@media (max-width: 768px) {
				height: 50px;
			}
			transition-property: transform;
			transition-duration: 300ms;
			@include touch-remove-hov {
				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}
}
</style>
<style>
.quality-text * {
	text-align: justify;
}
</style>