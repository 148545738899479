import * as types from '../mutations-type'

export default {
    namespaced: true,

    state: {
        contacts: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/contact-us').then(res => {
                commit(types.ABOUT_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        [types.ABOUT_STORE](state, contacts) {
            state.contacts = contacts
        }
    },

    getters: {
        getFacebook: state => state.contacts.find(e => e.Key === 'facebook')?.Value,
    }
}