<template>
	<div
		class="contact"
		data-aos="fade"
		data-aos-anchor-placement="top-buttom"
		data-aos-duration="500"
	>
		<div class="container py-5">
			<div class="row align-items-center justify-content-between">
				<div class="col-lg-1 d-lg-flex d-none">
					<div class="contact__btn center-item">
						<i
							class="fa"
							:class="isEnglish ? 'fa-long-arrow-right' : 'fa-long-arrow-left'"
						></i>
					</div>
				</div>
				<div class="col-lg-4 col-md-5 col-12 info mb-5">
					<h2 class="fw-bold fs-3 text-uppercase">
						{{ $t("contact.get-in-touch") }}
					</h2>
					<div class="contact__text mb-4 fs-5 fw-med">
						{{ $t("contact.contact-us") }}
						<br />
						{{ $t("contact.contact-us-2") }}
					</div>
					<div class="contact__contact">info@mirta.com</div>
					<!-- <div class="contact__contact">
						{{ $t("contact.address") }}
					</div>
					<div class="contact__contact">
						<a href="tel:+963956587459" dir="ltr"> +963 956 587 459 </a>
					</div> -->
					<div class="contact__contact" v-if="facebook">
						<a :href="facebook" target="_blank">
							<i class="fab fa-facebook-f"></i>
						</a>
					</div>
				</div>
				<div class="col-lg-6 col-md-7 col-12 mb-5">
					<div class="form">
						<h2 class="text-uppercase fw-bold t-c fs-4 mb-4">
							{{ $t("contact.send-us") }}
						</h2>
						<contact-form :invert="true"></contact-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
export default {
	components: {
		ContactForm,
		Breadcrumb,
	},
	computed: {
		isEnglish() {
			return document.querySelector("html").lang === "en";
		},

		...mapGetters({
			facebook: "contactUs/facebook",
		}),
	},
};
</script>

<style lang="scss">
@import "../../mixins.scss";
// @media (min-width: 768px) {
// 	.border-left {
// 	}
// 	.form {
// 		@include paddingEnd(3rem);
// 	}
// 	.info {
// 		@include paddingStart(3rem);
// 	}
// }
.contact {
	background: linear-gradient(
			var(--third-color-contact),
			var(--third-color-contact)
		),
		url("../../assets/Images/Contact_Background.jpg");
	background-size: cover;

	color: white;
	.form {
		border-radius: 20px;
		background: white;
		padding: 3rem 2rem;
	}
	&__text {
		max-width: 350px;
		line-height: 1.7em;
	}
	&__btn {
		border: 2px solid white;
		border-radius: 50%;
		width: 5rem;
		height: 5rem;
		@media (max-width: 1200px) {
			width: 4rem;
			height: 4rem;
		}
		> i {
			font-size: 2rem;
		}
		transition: 1s;
		&:hover {
			transition: 300ms;
			transform: scale(1.1);
		}
	}
}
</style>