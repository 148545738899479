<template>
	<div id="app">
		<loader v-if="loading"></loader>
		<div
			v-else
			data-aos="fade"
			data-aos-anchor-placement="top-buttom"
			data-aos-duration="500"
		>
			<h-header @changeLangEmit="changLang"></h-header>
			<router-view />
			<f-footer></f-footer>
		</div>
	</div>
</template>

<script>
import FFooter from "./components/FFooter.vue";
import HHeader from "./components/HHeader.vue";
import Loader from "./components/Loader.vue";
import { changeI18nLanguage } from "@/lang";
import AOS from "aos";

export default {
	data() {
		return {
			loading: true,
		};
	},
	components: {
		FFooter,
		HHeader,
		Loader,
	},
	methods: {
		changLang(l) {
			changeI18nLanguage(l);
			window.axios.defaults.headers.common["accept-language"] =
				this.$i18n.locale;
			this.fetch();
		},
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch("aboutUs/fetchAll"),
				this.$store.dispatch("slider/fetchAll"),
				this.$store.dispatch("QM/fetchAll"),
				this.$store.dispatch("products/fetchAll"),
				this.$store.dispatch("contactUs/fetchAll"),
			]).then(() => {
				this.loading = false;
			});
		},
	},
	setup() {
		AOS.init({
			startEvent: "load",
			once: true,
		});
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss">
@import "./helpers.scss";
@font-face {
	font-family: mainFont;
	src: url("./assets/Font/Poppins/Poppins-Regular.ttf");
	unicode-range: U+00-7F;
}
@font-face {
	font-family: mainFontBold;
	src: url("./assets/Font/Poppins/Poppins-SemiBold.ttf");
	unicode-range: U+00-7F;
}
@font-face {
	font-family: mainFontMed;
	src: url("./assets/Font/Poppins/Poppins-Medium.ttf");
	unicode-range: U+00-7F;
}
@font-face {
	font-family: mainFontAr;
	src: url("./assets/Font/Tajawal/Tajawal-Regular.ttf");
	unicode-range: U+600 –6FF;
}
@font-face {
	font-family: mainFontBoldAr;
	src: url("./assets/Font/Tajawal/Tajawal-Bold.ttf");
	unicode-range: U+600 –6FF;
}
@font-face {
	font-family: mainFontMedAr;
	src: url("./assets/Font/Tajawal/Tajawal-Medium.ttf");
	unicode-range: U+600 –6FF;
}

:root {
	--main-color: #ed1c24;
	--sec-color: #aedd89;
	--third-color: #095936;
	--third-color-o: #095936e7;
	--third-color-contact: #0959367b;
	--header-color: #09593660;

	--white-color: #fcfcfc;
}
.m-c {
	color: var(--main-color);
}
.s-c {
	color: var(--sec-color);
}
.t-c {
	color: var(--third-color);
}
body {
	background: var(--white-color);
}
* {
	font-size: 16px;
	font-family: mainFont, mainFontAr;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	@media (max-width: 1200px) {
		font-size: 15px;
	}
	@media (max-width: 768px) {
		font-size: 14px;
	}
}
.fw-bold {
	font-family: mainFontBold, mainFontBoldAr;
}
.fw-med {
	font-family: mainFontMed, mainFontMedAr;
}
.button {
	padding: 0.7em 2em;
	border-radius: 2rem;
	transition: 300ms;
	cursor: pointer;
	width: max-content;
	&:not(:disabled):hover {
		box-shadow: 0 3px 15px #0006;
		transform: translateY(-2px);
	}

	&__loader {
		display: block;
		width: 1em;
		height: 1em;
		border: 3px solid var(--color);
		border-bottom: 3px solid transparent;
		border-radius: 50%;
		animation: buttonLoader 1s infinite;
	}
	@keyframes buttonLoader {
		from {
			transform: rotate(0);
		}
		to {
			transform: rotate(360deg);
		}
	}

	&:disabled {
		cursor: default;
		filter: grayscale(0.5);
	}
}
.button--main {
	background: var(--main-color);
	color: white;
}
.button--main--lg {
	@media (min-width: 992px) {
		background: var(--main-color);
		color: white;
	}
}
.button--liner {
	border: 1px solid var(--btn-color);
	color: var(--btn-color);
}
</style>
