<template>
	<router-link :to="{ name: 'product', params: { id: product.id } }">
		<div class="product-card">
			<div class="product-card__image image">
				<img :src="product.primary_image" alt="" />
			</div>
			<div class="product-card__name fw-bold">
				{{ product.name }}
			</div>
		</div>
	</router-link>
</template>

<script>
export default {
	props: ["product"],
};
</script>

<style lang="scss">
.product-card {
	&__image {
		height: 300px;
		margin-bottom: 0.5rem;
		@media (max-width: 992px) {
			height: 250px;
		}
		transition: 200ms;
		&:hover {
			transform: translateY(-15px);
		}
	}
	&__name {
		text-align: center;
		max-width: 300px;
		margin: auto;
	}
}
</style>