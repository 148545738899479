import * as types from '../mutations-type'

export default {
    namespaced: true,

    state: {
        about: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/about-us').then(res => {
                commit(types.ABOUT_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        [types.ABOUT_STORE](state, about) {
            state.about = about
        }
    },

    getters: {
        getAbout: state => state.about.find(e => e.Key === 'about').Value,
        getMission: state => state.about.find(e => e.Key === 'mission').Value,
        getvision: state => state.about.find(e => e.Key === 'vision').Value,
    }
}