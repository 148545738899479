<template>
	<div class="breadcrumb">
		<ul>
			<li
				v-for="(link, i) in links"
				:key="i"
				:class="{ last: i === links.length - 1 }"
			>
				<router-link
					:to="link.path || { name: 'home' }"
					v-if="link.path || i === 0"
				>
					{{ link.name }}
				</router-link>
				<span v-else>
					{{ link.name }}
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: ["links"],
};
</script>

<style scoped lang="scss">
$color: #999;
$sec-color: var(--third-color);
$font-size: 0.9rem;
.breadcrumb {
	* {
		font-size: $font-size;
	}
	i {
		margin: 5px;
		margin-bottom: 0;
		color: $color;
	}
	ul {
		&::before {
			content: "\f015";
			font-family: "Font Awesome\ 5 Free";
			font-weight: 900;
			margin: 0 5px;
			color: $color;
			font-size: $font-size;
		}

		display: flex;
		flex-wrap: wrap;
		li:not(.last) {
			text-transform: capitalize;
			color: $color;
			& [href]:hover {
				color: $sec-color;
			}
			&::after {
				display: inline-block;
				content: "";
				width: 0.5em;
				height: 0.5em;
				border-bottom: 2px solid $color;
				border-right: 2px solid $color;
			}
		}
		li.last {
			text-transform: capitalize;
			color: $sec-color;
		}
	}
}
</style>
<style>
[dir="ltr"] .breadcrumb li:not(.last)::after,
[lang="en"] .breadcrumb li:not(.last)::after {
	transform: rotate(-45deg);
	margin-right: 10px;
}
[dir="rtl"] .breadcrumb li:not(.last)::after,
[lang="ar"] .breadcrumb li:not(.last)::after {
	transform: rotate(135deg);
	margin-left: 10px;
}
</style>