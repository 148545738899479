import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (to.path === from.path && to.hash.length === 0) { return; }
    // if (to.hash) {
    // 	document.getElementById(to.hash.substr(1)).scrollIntoView();
    // 	return;
    // }

    // document.documentElement.style.scrollBehavior = 'unset';
    document.documentElement.style.scrollBehavior = 'smooth';
    window.scrollTo({ top: 0 });
  },
})

export default router
