<template>
	<loader v-if="loading"></loader>
	<div
		class=""
		v-else
		data-aos="fade"
		data-aos-anchor-placement="top-buttom"
		data-aos-duration="500"
	>
		<div class="container">
			<breadcrumb
				class="mt-4"
				:links="[{ name: $t('breadcrumbs.home') }, { name: product.name }]"
			></breadcrumb>
			<div class="main row">
				<div
					class="
						product-images
						col-lg-7 col-md-6 col-12
						d-flex
						flex-lg-row-reverse flex-column
					"
				>
					<div class="main-image center-item">
						<div class="image w-75">
							<img :src="product.images[mainImage]" alt="" />
						</div>
					</div>
					<div class="other-image d-flex flex-lg-column col-row mt-lg-0 mt-3">
						<div
							class="other-image__item center-item cp"
							v-for="(img, i) in product.images"
							:key="i"
							:class="{ selected: i === mainImage }"
							@click="mainImage = i"
						>
							<div class="image">
								<img :src="img" alt="" />
							</div>
						</div>
					</div>
				</div>
				<div class="product-text col-lg-5 col-md-6 col-12 mt-md-0 mt-4">
					<h1 class="product-title fw-bold t-c text-uppercase mb-md-4 mb-2">
						{{ product.name }}
					</h1>
					<div
						class="product-description lite-color"
						v-html="product.description"
					></div>
				</div>
			</div>
		</div>
		<div class="other-products">
			<div class="container">
				<div class="other-products-title text-uppercase">
					{{ $t("product.other-products") }}
				</div>
				<div class="other-products-list">
					<div
						class="other-products-list__item center-item p-2 position-relative"
						v-for="product in products.filter(
							(e) => e.id != this.$route.params.id
						)"
						:key="product.id"
					>
						<div class="image">
							<img :src="product.primary_image" :alt="product.name" />
						</div>
						<router-link :to="{ name: 'product', params: { id: product.id } }">
							<div class="overflow fill-width fill-height center-item fs-3">
								{{ product.name }}
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Loader from "@/components/Loader.vue";
import { mapState, mapGetters } from "vuex";
export default {
	components: { Breadcrumb, Loader },
	data() {
		return {
			product: {},
			loading: false,

			mainImage: 0,
		};
	},
	computed: {
		...mapGetters({
			getProduct: "products/getProduct",
		}),
		...mapState({
			products: (state) => state.products.products,
		}),
	},
	watch: {
		"$route.params.id"() {
			window.scrollTo({ top: 0 });
			this.mainImage = 0;
			this.product = this.getProduct(this.$route.params.id);
		},
	},
	created() {
		this.product = this.getProduct(this.$route.params.id);
	},
};
</script>

<style scoped lang="scss">
@import "../../mixins.scss";
$grey-color: #999;
.main {
	margin: 3rem 0 4rem;
	align-items: flex-start;
}
.product-images {
	.main-image {
		border: 1px solid $grey-color;
		border-radius: 20px;
		padding: 40px 20px;
		@media (max-width: 576px) {
			padding: 30px;
		}
	}
	.other-image {
		@media (min-width: 992px) {
			@include paddingEnd(1.5rem);
		}
		@media (max-width: 400px) {
			flex-wrap: wrap;
		}
		&__item {
			width: 100px;
			height: 100px;
			padding: 10px;
			border: 2px solid $grey-color;
			border-radius: 10px;
			@media (max-width: 992px) {
				width: 24%;
				margin: 0 0.5%;
			}
			@media (max-width: 400px) {
				width: 49%;
				height: 42vw;
			}

			&:not(:last-child) {
				margin-bottom: 1rem;
				@media (max-width: 400px) {
					margin-bottom: 0.3rem;
				}
			}

			&.selected {
				border: 2px solid var(--third-color);
			}
		}
	}
}
.product-text {
	@media (min-width: 768px) {
		@include paddingStart(4rem);
	}
	.product-title {
		font-size: 1.7rem;
	}
}
.product-text-line {
	height: 1px;
	width: 100%;
	background: $grey-color;
	margin: 2rem 0;
}
.other-products {
	background: linear-gradient(var(--third-color-o), var(--third-color-o)),
		url("../../assets/Images/product_background.jpg");
	background-size: cover;
	background-position: bottom;
	padding: 5rem 0;

	color: white;
	&-title {
		font-size: 1.2rem;
		margin-bottom: 2rem;
	}
	&-list {
		display: grid;
		justify-content: space-between;
		row-gap: 20px;
		grid-template-columns: repeat(4, calc(25% - 10px));
		&__item {
			height: 300px;
			background: var(--white-color);
			border-radius: 10px;
			overflow: hidden;
			.overflow {
				background: #0008;
				opacity: 0;
				transition: 500ms;
				text-align: center;
				padding: 0.5rem;
			}
			.image {
				transition-property: transform;
				transition-delay: 300ms;
				transition-duration: 500ms;
			}
			&:hover .image {
				transform: scale(1.1);
			}
			&:hover .overflow {
				opacity: 1;
			}
		}
		@media (max-width: 992px) {
			grid-template-columns: repeat(3, calc(33.33% - 10px));
			&__item {
				height: 250px;
			}
			&__item:nth-child(4) {
				display: none;
			}
		}
		@media (max-width: 768px) {
			grid-template-columns: repeat(2, calc(50% - 5px));
			row-gap: 10px;
			&__item {
				height: 45vw;
				max-height: 250px;
			}
			&__item:nth-child(4) {
				display: block;
			}
		}
	}
}

.lite-color,
.lite-color * {
	color: $grey-color;
}
</style>