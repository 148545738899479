import Vue from 'vue'
import Vuex from 'vuex'
import products from "./modules/products"
import aboutUs from "./modules/aboutUs"
import slider from "./modules/slider"
import QM from "./modules/QM"
import feedback from "./modules/feedback"
import contactUs from "./modules/contactUs"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    products,
    aboutUs,
    slider,
    QM,
    feedback,
    contactUs
  }
})
