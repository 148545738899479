<template>
	<footer>
		<div class="container">
			<div class="top-footer d-flex flex-wrap align-items-center">
				<router-link :to="{ name: 'home' }">
					<div class="footer-brand">
						<div class="image">
							<img src="@/assets/Images/Logo.png" alt="" />
						</div>
					</div>
				</router-link>
				<div class="footer-line d-sm-block d-none"></div>
				<div class="footer-text fw-med">
					{{ $t("footer.text") }}
				</div>
				<div class="footer-links d-flex">
					<div class="footer-links__item fw-med text-uppercase cp">
						<router-link :to="{ name: 'home' }">
							{{ $t("footer.home") }}
						</router-link>
					</div>
					<div class="footer-links__item fw-med text-uppercase cp">
						<router-link :to="{ name: 'about-us' }">
							{{ $t("footer.about") }}
						</router-link>
					</div>
					<div
						class="footer-links__item fw-med text-uppercase cp"
						@click="scrollToProducts"
					>
						<router-link :to="{ name: 'home' }">
							{{ $t("footer.products") }}
						</router-link>
					</div>
					<div class="footer-links__item fw-med text-uppercase cp">
						<a href="https://mirta.coffee/" target="mirta">
							{{ $t("footer.mirta") }}
						</a>
					</div>
					<div class="footer-links__item fw-med text-uppercase cp">
						<router-link :to="{ name: 'contact-us' }">
							{{ $t("footer.contact") }}
						</router-link>
					</div>
				</div>
			</div>
			<div class="bottom-footer">
				All Rights Reserved &copy; 2022 .
				<a href="https://beetronix.com/" class="beetronix" target="_blank"
					>BEETRONIX</a
				>
			</div>
		</div>
	</footer>
</template>

<script>
import { hash } from "../variable";
export default {
	data() {
		return {
			hash,
		};
	},
	methods: {
		scrollToProducts() {
			hash.scroll = "#products-section";
		},
	},
};
</script>

<style scoped lang="scss">
@import "../mixins.scss";
$footer-font-size: 0.8rem;
.top-footer {
	padding: 2rem 0;
	border-bottom: 1px solid #ccc;
	.footer-brand {
		width: 5.5rem;
	}
	.footer-line {
		margin: 0 2rem;
		width: 1px;
		height: 3.4rem;
		background: #ccc;
	}
	.footer-text {
		font-size: $footer-font-size;
		line-height: $footer-font-size + 1rem;
		width: 260px;
		@include marginEnd(auto);
		@media (max-width: 576px) {
			@include marginStart(1.5rem);
			width: 200px;
		}
		@media (max-width: 320px) {
			width: 130px;
		}
	}
	.footer-links {
		&__item {
			font-size: $footer-font-size;
			@include marginEnd(1rem);
			&:hover {
				color: #555;
			}
		}
		@include marginStart(auto);
		@media (max-width: 992px) {
			margin-top: 2rem;
			@include marginStart(0);
		}
		@media (max-width: 400px) {
			flex-wrap: wrap;
		}
	}
}
.bottom-footer {
	padding: 1.8rem 0 2rem;
	font-size: 0.7rem;
	text-align: center;
	color: #ccc;
	* {
		font-size: 0.7rem;
	}
}
</style>