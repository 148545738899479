import * as types from '../mutations-type'

export default {
    namespaced: true,

    state: {
        sliders: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/sliders').then(res => {
                commit(types.SLIDER_STORE, res.data)
                return res.data
            })
        },
    },

    mutations: {
        [types.SLIDER_STORE](state, sliders) {
            state.sliders = sliders
        }
    }
}