<template>
	<loader v-if="loading"></loader>
	<div class="" v-else>
		<section class="about-us-section">
			<div class="container">
				<div class="row">
					<div
						class="
							col-lg-6 col-12
							d-flex
							align-items-start
							flex-lg-row flex-column
						"
					>
						<div
							class="about-us-section__icon section-icon"
							style="--icon-color: white; --height: 5rem"
						>
							<div class="image">
								<img src="@/assets/Icons/suitcase.png" alt="" />
							</div>
						</div>
						<div class="about-us-section__contant">
							<h2
								class="
									about-us-section__contant__title
									text-uppercase
									s-c
									fw-med
								"
							>
								{{ $t("home.who-we-are") }}
							</h2>
							<div class="about-container">
								<div
									class="about-us-section__contant__description"
									:class="{ overflow: aboutOverflow, show: showAbout }"
									ref="about"
									v-html="about"
								></div>
								<div
									class="about-container-show m-c fw-med cp"
									@click="showAbout = true"
									:class="{ 'd-none': !aboutOverflow || showAbout }"
								>
									{{ $t("read-more") }}
								</div>
							</div>
						</div>
					</div>
					<div
						class="
							col-lg-6 col-12
							d-flex
							align-items-start
							flex-lg-row flex-column
						"
					>
						<div
							class="about-us-section__icon section-icon"
							style="--icon-color: white; --height: 5rem"
						>
							<div class="image">
								<img src="@/assets/Icons/settings.png" alt="" />
							</div>
						</div>
						<div class="about-us-section__contant">
							<h2
								class="
									about-us-section__contant__title
									text-uppercase
									s-c
									fw-med
								"
							>
								{{ $t("home.quality") }}
							</h2>
							<div class="about-container">
								<div
									class="about-us-section__contant__description"
									:class="{ overflow: qualityOverflow, show: showQuality }"
									ref="quality"
									v-html="quality[0].Content"
								></div>
								<div
									class="about-container-show m-c fw-med cp"
									@click="showQuality = true"
									:class="{ 'd-none': !qualityOverflow || showQuality }"
								>
									{{ $t("read-more") }}
								</div>
							</div>
						</div>
					</div>
					<router-link :to="{ name: 'about-us' }">
						<div
							class="about-us-section__btn button button--main text-uppercase"
							style="--btn-color: white"
						>
							{{ $t("home.about-us") }}
						</div>
					</router-link>
				</div>
			</div>
		</section>
		<section class="products-section" id="products-section">
			<div class="container">
				<div class="d-flex align-items-end">
					<div
						class="section-icon product-section__icon"
						style="--icon-color: var(--sec-color); --height: 3rem"
					>
						<div class="image">
							<img src="@/assets/Icons/cubes.png" alt="" />
						</div>
					</div>
					<h2 class="products-section__title fw-bold text-uppercase mb-2">
						{{ $t("home.our-products") }}
					</h2>
				</div>
				<div class="products-section__grid row mt-0 gy-4">
					<product-card
						class="col-lg-3 col-md-4 col-sm-6 col-12 px-3"
						data-aos="fade"
						data-aos-anchor-placement="top-center"
						v-for="product in products"
						:key="product.id"
						:product="product"
					></product-card>
				</div>
			</div>
		</section>
		<section class="get-in-touch-section">
			<div class="container">
				<div
					class="
						row
						gy-4
						align-items-center
						justify-content-lg-evenly justify-content-md-center
					"
				>
					<div class="get-in-touch-section__contant col-lg-3 col-md-12">
						<h2
							class="
								get-in-touch-section__contant__title
								fw-bold
								text-uppercase
							"
						>
							{{ $t("home.get-in-touch") }}
						</h2>
						<div class="get-in-touch-section__contant__text">
							{{ $t("home.get-in-touch-text") }}
							<br />
							{{ $t("home.get-in-touch-text-2") }}
						</div>
						<div class="get-in-touch-section__contant__contact s-c">
							info@mirta.com
						</div>
						<!-- <div class="get-in-touch-section__contant__contact">
							{{ $t("contact.address") }}
						</div>
						<div class="get-in-touch-section__contant__contact">
							<a href="tel:+963956587459" dir="ltr"> +963 956 587 459 </a>
						</div> -->
						<div class="get-in-touch-section__contant__contact" v-if="facebook">
							<a :href="facebook" target="_blank">
								<i class="fab fa-facebook-f"></i>
							</a>
						</div>
					</div>
					<div class="get-in-touch-section__form col-lg-6 col-md-12">
						<contact-form></contact-form>
					</div>
					<div
						class="get-in-touch-section__btn col-2 d-lg-flex d-none center-item"
					>
						<i
							class="fa"
							:class="isEnglish ? 'fa-long-arrow-right' : 'fa-long-arrow-left'"
						></i>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import ProductCard from "../components/ProductCard.vue";
import ContactForm from "../components/ContactForm.vue";
import Loader from "@/components/Loader.vue";
import { mapState, mapGetters } from "vuex";
import { hash } from "../variable";
export default {
	data() {
		return {
			loading: false,
			aboutOverflow: false,
			showAbout: false,

			qualityOverflow: false,
			showQuality: false,

			hash,
		};
	},
	components: {
		ProductCard,
		ContactForm,
		Loader,
	},
	computed: {
		isEnglish() {
			return document.querySelector("html").lang === "en";
		},
		...mapGetters({
			about: "aboutUs/getAbout",
			facebook: "contactUs/facebook",
		}),
		...mapState({
			products: (state) => state.products.products,
			quality: (state) => state.QM.qm,
		}),
	},
	mounted() {
		setTimeout(() => {
			if (hash.scroll) {
				console.log();
				window.scrollTo({
					top:
						document.querySelector(hash.scroll).getClientRects()[0].top + 450,
				});
				hash.scroll = false;
			}

			this.aboutOverflow = this.$refs.about.clientHeight > 425;
			this.qualityOverflow = this.$refs.quality.clientHeight > 425;
		}, 30);
	},
	watch: {
		"hash.scroll"() {
			if (hash.scroll) {
				setTimeout(() => {
					document.querySelector(hash.scroll).scrollIntoView();
					hash.scroll = false;
				}, 30);
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import "../mixins.scss";
.section-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	@include marginEnd(2rem);
	.image {
		border-radius: 50%;
		width: 4rem;
		height: 4rem;
		padding: 1.2rem;
		border: 1px solid var(--icon-color);
	}

	&::before {
		content: "";
		width: 1px;
		height: var(--height);
		background: var(--icon-color);
	}
	@media (max-width: 992px) {
		flex-direction: row;
		&::before {
			height: 1px;
			width: 200px;
			position: absolute;
			top: 50%;
			@include right(100%);
		}
	}
}
.about-us-section {
	background: linear-gradient(70deg, var(--third-color) 20%, transparent 150%),
		url("../assets/Images/About_background.jpg");
	background-size: 100% auto;
	background-position: bottom;
	background-repeat: no-repeat;
	color: white;
	&__contant {
		padding: 5rem 0 3rem;
		@include paddingEnd(25%);
		&__title {
			font-size: 2rem;
		}
		&__sub-title {
			font-size: 1.8rem;
			line-height: 2rem;
		}
		@media (max-width: 1200px) {
			@include paddingEnd(3rem);
		}
		@media (max-width: 992px) {
			padding: 2rem 1rem 4rem !important;
		}
	}
	@media (max-width: 992px) {
		padding-top: 5rem;
		background-size: auto 100%;
	}
	&__btn {
		margin-bottom: 5rem;
		font-size: 0.9rem;
		@include marginStart(7rem);
		@media (max-width: 992px) {
			@include marginStart(1.5rem);
		}
	}
}
.about-container {
	.overflow {
		line-height: 25px;
		max-height: 425px;
		overflow: hidden;
		transition: 200ms;
		position: relative;
		&.show {
			max-height: 600px;
		}
	}
}
.products-section {
	&__title {
		font-size: 1.6rem;
	}
	&__grid {
		@include paddingStart(4rem);
		padding-bottom: 4rem;
		@media (max-width: 992px) {
			@include paddingStart(0);
		}
	}
	@media (max-width: 992px) {
		padding-top: 5rem;
	}
}
.get-in-touch-section {
	background-image: linear-gradient(var(--third-color-o), var(--third-color-o)),
		url("../assets/Images/Contact_Background.jpg");
	background-size: 100% auto;
	background-position: center;
	@media (max-width: 992px) {
		background-size: cover;
	}
	color: white;
	padding: 3rem 0 2rem;
	&__contant {
		order: 2;
		padding: 0 2rem;
		@media (max-width: 992px) {
			padding: 0 1rem;
		}
		&__title {
			font-size: 1.8rem;
		}
		&__text {
			color: #ccc;
			font-size: 0.9rem;
			line-height: 1.7rem;
			margin-bottom: 2rem;
		}
		&__contact {
			font-size: 0.8rem;
			i {
				font-size: 0.7rem;
			}
		}
	}
	&__form {
		order: 3;
	}
	&__btn {
		order: 1;
		border: 1px solid var(--sec-color);
		border-radius: 50%;
		width: 5rem;
		height: 5rem;
		transition: 1s;
		&:hover {
			transition: 300ms;
			transform: scale(1.1);
		}
		i {
			font-size: 2rem;
		}
		@media (max-width: 992px) {
			order: 4;
		}
	}
}
</style>

<style>
.about-us-section__contant__description p {
	margin-bottom: 0 !important;
	text-align: justify;
}
</style>
