import Home from "@/views/Home.vue";
import Products from "../views/Products/Products.vue";
import ProductsView from "../views/Products/ProductView.vue";
import productsSearch from "../views/Products/ProductsSearch.vue";
import AboutUs from "../views/AboutUs/AboutUs.vue"
import ContactUs from "../views/ContactUs/ContactUs.vue"

export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/products',
        name: 'all-products',
        component: Products
    },
    {
        path: '/product/:id',
        name: 'product',
        component: ProductsView
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/search',
        name: 'search',
        component: productsSearch
    },
]