import * as types from '../mutations-type'

export default {
    namespaced: true,

    state: {
        qm: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/q-m').then(res => {
                commit(types.QM_STORE, res.data)
                return res.data
            })
        },
        fetchById({ commit }, { id }) {
            return window.axios.get(`/q-m/${id}`).then(res => {
                return res.data
            })
        }
    },

    mutations: {
        [types.QM_STORE](state, qm) {
            state.qm = qm
        }
    }
}