import * as types from '../mutations-type'

export default {
    namespaced: true,

    state: {
        products: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/products').then(res => {
                commit(types.PRODUCTS_STORE, res.data)
                return res.data
            })
        },
        fetchById({ commit }, { id }) {
            return window.axios.get(`/products/${id}`).then(res => {
                return res.data
            })
        },
        fetchBySearch({ commit }, { search }) {
            return axios.get('/products/search', { params: { text: search } })
                .then((response) => {
                    return response.data
                })
        }
    },

    mutations: {
        [types.PRODUCTS_STORE](state, products) {
            state.products = products
        }
    },

    getters: {
        getProduct: state => id => state.products.find(e => e.id == id)
    }
}