<template>
	<div class="h-header" ref="header">
		<div class="container">
			<div class="nav-container">
				<nav
					dir="ltr"
					class="d-flex justify-content-between align-items-center container"
				>
					<router-link :to="{ name: 'home' }">
						<div class="brand">
							<div class="image">
								<img src="@/assets/Images/Logo.png" alt="" />
							</div>
						</div>
					</router-link>

					<a
						href="https://mirta.coffee/"
						target="mirta"
						class="other-site d-flex align-items-center flex-row-reverse"
					>
						<div class="other-site-image">
							<div class="image">
								<img src="@/assets/Icons/coffee-cup.png" alt="" />
							</div>
						</div>
						<div class="other-site-text text-end">
							<div class="other-site-name text-capitalize">
								{{ $t("other-site.name") }}
							</div>
							<div class="other-site-site">
								<i class="fa fa-angle-left" style="font-size: 0.8em"></i>
								{{ $t("other-site.site") }}
							</div>
						</div>
					</a>
					<div class="drawer p-3 d-lg-none" @click="showNav = true" ignorClick>
						<i class="fa fa-bars fs-3"></i>
					</div>
					<div
						class="links d-flex align-items-center"
						:class="{ show: showNav }"
						ignorClickJustHere
					>
						<div
							class="links__item links__item--close d-lg-none"
							@click="showNav = false"
							ignorClick
						>
							<i class="fa fa-times fs-3"></i>
						</div>
						<router-link :to="{ name: 'home' }">
							<div class="links__item text-uppercase fw-med ms-lg-4 cp">
								{{ $t("header.links.home") }}
							</div>
						</router-link>
						<router-link :to="{ name: 'home' }">
							<div
								class="links__item text-uppercase fw-med ms-lg-4 cp"
								@click="scrollToProducts"
							>
								{{ $t("header.links.products") }}
							</div>
						</router-link>
						<router-link :to="{ name: 'about-us' }">
							<div class="links__item text-uppercase fw-med ms-lg-4 cp">
								{{ $t("header.links.about") }}
							</div>
						</router-link>
						<router-link :to="{ name: 'contact-us' }">
							<div
								class="
									links__item links__item--small
									text-uppercase
									fw-med
									ms-lg-4
									cp
									button button--main--lg
								"
							>
								{{ $t("header.links.contact") }}
							</div>
						</router-link>
						<div
							class="links__item search text-uppercase fw-med ms-lg-4 cp"
							ignorClick
						>
							<i
								class="pe-lg-1 ps-lg-0 fa"
								@click="showSearch = !showSearch"
								:class="showSearch ? 'fa-times' : 'fa-search'"
							></i>
							<div
								class="search-input center-item"
								:class="{ show: showSearch }"
							>
								<form
									class="px-2"
									action=""
									@submit.prevent="
										$router.push({ name: 'search', query: { q: searchName } })
									"
								>
									<input
										type="text"
										v-model="searchName"
										:placeholder="$t('search')"
									/>
									<button role="submit"><i class="fa fa-search"></i></button>
								</form>
							</div>
						</div>
						<div
							class="
								links__item
								lang
								text-uppercase
								fw-med
								ms-lg-4
								cp
								position-relative
							"
							@click="showLang = !showLang"
							ignorClick
						>
							<i class="fa fa-globe"></i>
							<div class="dropdown-container" :class="{ show: showLang }">
								<div class="dropdown shadow">
									<div
										class="dropdown-item p-lg-1 p-2 fw-med"
										@click="changeLang('en')"
									>
										English
									</div>
									<div
										class="dropdown-item p-lg-1 p-2 fw-med"
										@click="changeLang('ar')"
									>
										عربي
									</div>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>

			<header>
				<div class="slider">
					<Hooper
						:mouseDrag="false"
						:wheelControl="false"
						:autoPlay="true"
						:playSpeed="3000"
						:hoverPausse="true"
						:infiniteScroll="slider.length > 1"
						@slide="changeSliderImage"
						:rtl="!isEnglish"
					>
						<slide v-for="slide in slider" :key="slide.id">
							<div class="slider__text" v-html="slide.description"></div>
						</slide>
					</Hooper>
					<div class="slider__buttons d-flex">
						<div
							class="slider__button text-uppercase button button--main"
							@click="scrollToProducts()"
						>
							{{ $t("header.products") }}
						</div>
						<router-link :to="{ name: 'contact-us' }">
							<div
								class="slider__button text-uppercase button button--liner"
								style="--btn-color: white"
							>
								{{ $t("header.contact") }}
							</div>
						</router-link>
					</div>
				</div>
			</header>
		</div>
	</div>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import { mapState } from "vuex";
import { hash } from "../variable";

export default {
	data() {
		return {
			showNav: false,
			showSearch: false,
			showLang: false,

			searchName: null,

			hash,
		};
	},
	methods: {
		changeSliderImage(payload) {
			this.$refs.header.style.cssText = `background-image: linear-gradient(var(--header-color), var(--header-color)), url(${
				this.slider[payload.currentSlide % this.slider.length]?.image
			});`;
		},
		changeLang(l) {
			this.$emit("changeLangEmit", l);
		},
		scrollToProducts() {
			hash.scroll = "#products-section";
		},
	},
	mounted() {
		if (this.$route.name === "home") this.$refs.header.classList.add("home");

		const ignoredElem = document.querySelectorAll("[ignorClick]");
		const ignoredOneElem = document.querySelectorAll("[ignorClickJustHere]");
		window.addEventListener("click", (event) => {
			for (let i = 0; i < ignoredElem.length; i++) {
				const e = ignoredElem[i];

				if (e.contains(event.target)) return;
			}
			for (let i = 0; i < ignoredOneElem.length; i++) {
				const e = ignoredOneElem[i];

				if (e === event.target) return;
			}

			this.showNav = false;
			this.showLang = false;
		});
	},
	watch: {
		$route() {
			if (this.$route.name === "home") this.$refs.header.classList.add("home");
			else {
				this.$refs.header.classList.remove("home");
			}
			this.showSearch = false;
			this.showNav = false;
			this.searchName = null;
		},
	},
	computed: {
		isEnglish() {
			return document.querySelector("html").lang === "en";
		},
		...mapState({
			slider: (state) => state.slider.sliders,
		}),
	},
	components: {
		Hooper,
		Slide,
	},
};
</script>

<style scoped lang="scss">
@import "../mixins.scss";
.h-header:not(.home) {
	background: var(--third-color) !important;
}
.home.h-header {
	background: linear-gradient(var(--header-color), var(--header-color)),
		url("../assets/Images/Header_background.jpg");
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	@media (max-width: 992px) {
		background-size: cover;
	}
	border-bottom: 5px solid var(--white-color);
}
.brand {
	transition: .2s;
	width: 5rem;
	filter: brightness(100);
}
.home .brand {
	filter: none;
}
nav {
	border-bottom: 1px solid transparent;
	padding: 2.5rem 0 2rem;
	.links {
		@include justArabic {
			direction: rtl;
		}
		&__item {
			font-size: 0.9rem;
			color: white;
			&:hover {
				color: #eee;
			}
			text-shadow: 0 0 5px #666;
			&--small {
				font-size: 0.8rem;
			}
			&--close {
				position: absolute;
				right: 3rem;
				top: 1rem;
				padding: 1rem !important;
				@media (max-width: 576px) {
					right: 1rem;
				}
			}
			@media (max-width: 992px) {
				padding: 1.5rem 0;
			}
		}
		@media (max-width: 992px) {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			z-index: 20;

			flex-direction: column;
			background: #000d;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;

			padding: 0;
			max-height: 0;
			transition: 0.5s;
			overflow: hidden;
			&.show {
				max-height: 600px;
				padding: 4rem 0 2rem;
			}
		}
	}
	.drawer {
		color: white;
	}
}
.other-site {
	display: block;
	color: white;
	margin-right: auto;
	margin-left: 2rem;
	&-image {
		width: 3rem;
		@include marginStart(10px);
		filter: invert(1);
	}
	cursor: pointer;
	transition: 300ms;
	&:hover {
		transform: scale(1.05);
	}
	&-name {
		font-family: mainFontBold;
		font-size: 1.1rem;
	}
	&-site {
		font-size: 0.6em;
	}
}
.home nav {
	border-bottom: 1px solid var(--white-color);
}
header {
	max-height: 0;
	padding: 0;
	overflow: hidden;
	transition: 400ms;
}
.home header {
	padding: 8rem 0 10rem;
	max-height: 500px;
}
.slider {
	max-width: 50%;
	@media (max-width: 768px) {
		max-width: 100%;
	}

	&__button {
		font-size: 0.8rem;
		&:first-child {
			@include marginEnd(1rem);
		}
	}
}

.search {
	display: flex;
	align-items: center;
	i {
		margin-right: 0.3rem;
	}
	&-input {
		background: var(--white-color);
		border-radius: 2rem;
		max-width: 0;
		max-height: 0;
		height: 2rem;
		font-size: 0.8rem;
		transition: 300ms;
		overflow: hidden;
		input {
			width: 88%;
		}
		&.show {
			max-width: 200px;
			max-height: 2rem;
		}
	}
}

.dropdown-container {
	position: absolute;
	top: 100%;
	right: -25px;

	overflow: hidden;
	max-height: 0;
	padding: 0;
	padding-top: 0;
	transition: 300ms;
	z-index: 100;
	&.show {
		padding: 20px;
		padding-top: 1.5rem;
		max-height: 200px;
	}
	.dropdown {
		background: var(--white-color);
		padding: 2rem 1rem;
		border-radius: 20px;
		text-align: center;
		color: black;
		> &-item {
			text-shadow: none;
			&:hover {
				color: var(--main-color);
			}
		}
	}
}
@media (max-width: 992px) {
	.lang {
		display: flex;
		flex-flow: column;
		align-items: center;
		.dropdown-container {
			position: static;
			padding: 0 !important;
			.dropdown {
				box-shadow: unset !important;
				background: transparent;
				&-item {
					color: white;
				}
			}
		}
	}
}

@keyframes navEnter {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}
</style>
<style>
.slider__text,
.slider__text * {
	font-size: 3rem;
	line-height: 3.2rem;
	color: white;
	margin-bottom: 3rem;
	font-family: mainFontBold, mainFontBoldAr;
	word-break: break-word;
}
@media (max-width: 576px) {
	.slider__text,
	.slider__text * {
		font-size: 2.3rem;
	}
}
</style>